<script setup>
const { t } = useI18n();
</script>
<template>
  <!-- LOGO -->
  <NuxtLink to="/" class="flex items-center !bg-transparent cursor-pointer">
    <div class="flex items-center relative">
      <NuxtImg
        provider="awsS3"
        id="logo"
        src="/logo.png"
        class="h-7 mr-3 sm:h-9"
        alt="Smartlnks Logo"
        data-not-lazy
      />
      <span class="md:text-2xl text-xl text-black cursor-pointer font-semibold">{{
        t("smartlnks")
      }}</span>
    </div>
  </NuxtLink>
</template>
<style></style>
