<template>
  <div>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1"
    />
    <AlertMessage />
    <NavbarHome />
    <main>
      <slot />
    </main>
    <DefaultFooter/>
  </div>
</template>
<style scoped></style>
