<script setup>
import { required, maxLength, helpers, url } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { linksUtilityApiService } from "~/services/linksUtilityApiService.js";
import { removeAllParams } from "~/utils/urls.js";

const props = defineProps({
  showInputField: {
    type: Boolean,
    default: false,
  },
  header: {
    type: String,
    default: "Check Link Insights",
  },
  url: {
    type: String,
    required: false,
  },
});

const { fetchPublicLinkInsights } = linksUtilityApiService();

const form = reactive({
  url: "",
});

const formRules = {
  url: {
    required: helpers.withMessage("Smartlnk is required", required),
    maxLength: helpers.withMessage(
      "Smartlnk must be 200 characters or less",
      maxLength(200)
    ),
    url: helpers.withMessage("The entered value is not a valid URL address", url),
  },
};

const formV$ = useVuelidate(formRules, form);

const responseError = ref();
const isSubmitted = ref(false);
const destination_url = ref();
const responseData = ref();
const loading = ref(false);

async function onSubmitted(isFormValid) {
  isSubmitted.value = true;

  if (isFormValid) {
    return;
  }

  loading.value = true;

  resetResponseError();

  const response = await fetchPublicLinkInsights({ url: removeAllParams(form.url) });

  console.log("==> response: ", response);
  if (response.status === 200) {
    resetResponseError();
    window.open(response.data?.data.insightUrl, "_blank");
  } else {
    responseError.value =
      response.data?.details ||
      "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.";
  }

  loading.value = false;
  isSubmitted.value = false;
}

const resetResponseError = () => {
  responseError.value = null;
};
</script>

<template>
  <div>
    <Dialog
      :header="header"
      :visible="true"
      @update:visible="$emit('closeDialog')"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :modal="true"
      :dismissableMask="true"
      id="link-insights-dialog"
    >
      <h2 class="text-sm text-gray-600">
        Enter the Smartlnk/domain you want to check for link analytics. Please note that
        this feature is available only for public Smartlnks/domains created by
        unauthenticated users. Public links can be accessed by anyone to view their
        analytics.
      </h2>
      <div class="grid grid-cols-1 gap-1">
        <div v-if="showInputField" class="col-span-1 mt-10">
          <CustomFieldText
            :Field="formV$.url"
            :isSubmitted="isSubmitted"
            :responseError="responseError"
            :label="'Enter Smartlnk/domain'"
            :inputId="'smart-lnk-domain-input-field'"
            :placeholder="'Type or paste a smartlnk/domain'"
            :showResponseError="true"
            :helpText="'Please include \'https://\' or \'http://\' in the link. For example: \'https://smartlnks.com/alias\' or \'http://prefix.smartlnks.com/postfix\'.'"
            @clearResponseError="resetResponseError()"
          />
          <br />

          <Button
            label="Check Link Insights"
            class="w-full"
            :loading="loading"
            @click="onSubmitted(formV$.$invalid)"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<style lang="postcss">
#link-insights-dialog {
  @apply w-[80%] md:!w-[30%];
}
#link-insights-dialog > .p-dialog-header {
  @apply pb-0;
}
</style>
