<script setup>
import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";

const { isAuthenticated } = storeToRefs(useAuthStore());
</script>
<template>
  <div>
    <NuxtLink
      class="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm md:text-base px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-black dark:hover:bg-black dark:focus:ring-black relative animate-wiggle"
      :class="{ 'animate-wiggle': !isAuthenticated }"
      :to="isAuthenticated ? '/dashboard/collection' : '/signin'"
    >
      {{ isAuthenticated ? "Go to dashboard" : "Get started" }}
      <!-- <div v-if="!isAuthenticated" class="absolute top-[-4rem]">
        <Vue3Lottie
          animationLink="https://lottie.host/16eff556-661e-480b-96b7-08f47a1a2df4/LQpQVyM5uD.json"
          :height="200"
          noMargin
        />
      </div> -->
    </NuxtLink>
  </div>
</template>

<style scoped></style>
