<script setup>
import { onClickOutside } from "@vueuse/core";
import { showScanLinkDialog, showLinkPreviewDialog } from "~/utils/dialogActions";

const isDropdownOpen = ref(false);

const toggleDropdown = () => (isDropdownOpen.value = !isDropdownOpen.value);

const target = ref(null);
onClickOutside(target, (event) => (isDropdownOpen.value = false));

const buttons = [
  {
    label: "Scan SmartLnks",
    action: showScanLinkDialog,
  },
  {
    label: "Preview SmartLnks",
    action: showLinkPreviewDialog,
  },
];
</script>
<template>
  <div ref="target">
    <Button
      id="tools-dropdown-navbar-link"
      label="Tools"
      :icon="` pi ${isDropdownOpen ? 'pi-angle-up' : 'pi-angle-down'}`"
      iconPos="right"
      @click="toggleDropdown"
      data-dropdown-toggle="toolsDropdownNavbar"
    />

    <!-- Dropdown menu -->
    <div
      id="toolsDropdownNavbar"
      :class="{ hidden: !isDropdownOpen }"
      class="bg-white md:absolute text-base z-10 list-none divide-y divide-gray-100 rounded shadow mb-4 md:mt-4 mt-0 w-full md:w-52"
    >
      <ul class="py-1 md:px-4" aria-labelledby="dropdownLargeButton">
        <li v-for="(btn, index) in buttons" :key="index">
          <button
            type="button"
            class="block py-2 pl-3 pr-4 text-gray-700 rounded w-full md:hover:bg-transparent md:hover:text-primary md:p-0 md:py-2.5 md:text-start md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
            @click="btn.action()"
          >
            {{ btn.label }}
          </button>
        </li>
        <li>
          <ButtonCheckLinkInsights />
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="postcss">
#tools-dropdown-navbar-link {
  @apply text-gray-700 !bg-transparent border-none text-base hover:bg-gray-50 md:hover:bg-transparent pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 font-medium flex items-center justify-center w-full md:w-auto;
}
</style>
