<script setup></script>

<template>
  <!-- ====== Footer Section Start -->
  <footer
    class="relative z-30 bg-white dark:bg-dark pt-20 pb-10 lg:pt-[120px] lg:pb-20 px-4 sm:px-6 lg:px-8 mx-auto"
  >
    <div class="container mx-auto">
      <div class="flex flex-wrap -mx-4">
        <DefaultFooterInfo />

        <DefaultFooterLinks />

        <DefaultFooterSocials />
      </div>
    </div>
    <div
      class="flex md:flex-row flex-col md:justify-between justify-center items-center md:px-6"
    >
      <iframe
        title="Status"
        src="https://status.smartlnks.co/badge?theme=light"
        width="250"
        height="30"
        frameborder="0"
        scrolling="no"
      ></iframe>
      <p class="md:mt-0 mt-7">Made in Love ❤️</p>
    </div>
    <DefaultFooterBg />
  </footer>
  <!-- ====== Footer Section End -->
</template>
