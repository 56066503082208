<script setup></script>

<template>
  <div class="w-full px-4 sm:w-2/3 lg:w-3/12">
    <div class="w-full mb-10">
      <NuxtLink to="/" class="mb-6 inline-flex items-center gap-4 max-w-[160px]">
        <NuxtImg
          provider="awsS3"
          src="/logo.png"
          alt="logo"
          class="max-w-full h-10 dark:hidden"
        />
        <h2 class="text-2xl font-semibold">SmartLnks</h2>
      </NuxtLink>
      <p class="text-base text-body-color dark:text-dark-6 mb-7">
        SmartLnks: Elevating every link to stardom! ✨ Your journey to smarter URLs starts
        here.
      </p>
      <p class="flex items-center text-sm font-medium text-dark dark:text-white">
        <span class="mr-3 text-primary text-lg">
          <SvgoMail />
        </span>
        <NuxtLink to="mailto:team@smartlnks.com" target="_blank"
          >team@smartlnks.com</NuxtLink
        >
      </p>
    </div>
  </div>
</template>

<style></style>
