<script setup>
import { NavbarHomeTools } from "#components";
import { removeAllParams } from "~/utils/urls.js";

const props = defineProps({
  toggleNavigation: {
    required: true,
  },
});

const route = useRoute();

const ulClass = computed(() => {
  const baseClasses = [
    "flex p-4 border border-gray-100 rounded-lg dark:bg-gray-800 dark:border-gray-700",
    "md:space-x-8 md:text-sm md:font-medium md:border-0 md:dark:bg-gray-900",
  ];

  const toggleClasses = props.toggleNavigation
    ? "absolute w-full text-center !bg-white"
    : "";

  const backgroundClass =
    removeAllParams(route.fullPath) === "/" ? "bg-transparent" : "bg-white";

  const flexDirectionClass = "flex-col mt-4 md:flex-row md:mt-0";

  return [...baseClasses, toggleClasses, backgroundClass, flexDirectionClass].join(" ");
});

const navLinks = [
  { name: "Home", to: "/", isExternal: false },
  { name: "Tools", isComponent: true, component: NavbarHomeTools },
  // { name: "Telegram Bot", to: "https://telegram.smartlnks.com", isExternal: true },
  { name: "Extension", to: "https://extension.smartlnks.com", isExternal: true },
  { name: "YouTube Permanent Link", to: "/smartytlink", isExternal: false },
  { name: "Blog", to: "/blog", isExternal: false },
];

const linkClass =
  "transition ease-in-out duration-500 block py-2 pl-3 pr-4 w-full text-base text-gray-700 rounded md:bg-transparent md:hover:text-primary md:p-0 dark:text-white dark:hover:bg-gray-700";
</script>

<template>
  <ul :class="ulClass">
    <li v-for="(link, index) in navLinks" :key="index">
      <template v-if="link.isComponent">
        <component :is="link.component" />
      </template>
      <template v-else>
        <NuxtLink
          :to="link.to"
          :target="link.isExternal ? '_blank' : undefined"
          :class="linkClass"
          @click="$emit('toggleDropdownCallback')"
        >
          {{ link.name }}
        </NuxtLink>
      </template>
    </li>
  </ul>
</template>

<style lang="postcss" scoped>
.router-link-active {
  @apply md:text-primary text-white bg-black md:bg-transparent md:hover:text-primary;
}
</style>