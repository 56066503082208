<script setup></script>

<template>
  <div class="w-full px-4 sm:w-1/2 lg:w-3/12">
    <div class="w-full mb-10">
      <h3 class="text-lg font-semibold text-dark dark:text-white mb-9">Follow Us On</h3>
      <div class="flex items-center mb-6">
        <NuxtLink
          to="https://www.linkedin.com/company/smartlnks"
          target="_blank"
          class="flex items-center justify-center w-8 h-8 mr-3 border rounded-full text-dark hover:border-primary hover:bg-primary border-stroke dark:border-dark-3 dark:hover:border-primary dark:text-white hover:text-white sm:mr-4 lg:mr-3 xl:mr-4"
        >
          <SvgoLinkedin />
        </NuxtLink>
        <NuxtLink
          to="https://twitter.com/smartlnks"
          target="_blank"
          class="flex items-center justify-center w-8 h-8 mr-3 border rounded-full text-dark hover:border-primary hover:bg-primary border-stroke dark:border-dark-3 dark:hover:border-primary dark:text-white hover:text-white sm:mr-4 lg:mr-3 xl:mr-4"
        >
          <SvgoX />
        </NuxtLink>
        <NuxtLink
          to="https://www.instagram.com/smartlnks.com_"
          target="_blank"
          class="flex items-center justify-center w-8 h-8 mr-3 border rounded-full text-dark hover:border-primary hover:bg-primary border-stroke dark:border-dark-3 dark:hover:border-primary dark:text-white hover:text-white sm:mr-4 lg:mr-3 xl:mr-4"
        >
          <SvgoInstagram />
        </NuxtLink>
        <NuxtLink
          to="https://telegram.smartlnks.com"
          target="_blank"
          class="flex items-center justify-center w-8 h-8 mr-3 border rounded-full text-dark hover:border-primary hover:bg-primary border-stroke dark:border-dark-3 dark:hover:border-primary dark:text-white hover:text-white sm:mr-4 lg:mr-3 xl:mr-4"
        >
          <SvgoTelegram />
        </NuxtLink>
      </div>
      <p class="text-base text-body-color dark:text-dark-6">
        &copy; {{ new Date().getFullYear() }} SmartLnks
      </p>
    </div>
  </div>
</template>

<style></style>
