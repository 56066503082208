<!-- TODO: fix this after api response formatting -->
<script setup>
const showDialog = ref(false);
</script>

<template>
  <div>
    <button
      type="button"
      @click="showDialog = true"
      class="block py-2 pl-3 pr-4 text-gray-700 rounded w-full md:hover:bg-transparent md:hover:text-primary md:p-0 md:py-2.5 md:text-start md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
    >
      <!-- added md:py-2.5 md:text-start -->
      Check Link Analytics
    </button>
    <DialogLinkInsights
      v-if="showDialog"
      :showDialog="showDialog"
      :showInputField="true"
      @closeDialog="showDialog = false"
    />
  </div>
</template>

<style scoped></style>
