<script setup>
import { useOneTap } from "vue3-google-signin";
import { notifyError } from "~/utils/notifications.js";
import { useGoogleLoginApi } from "~/composables/useGoogleLoginApi";
import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";

const { googleLoginApi, loading } = useGoogleLoginApi();

const { isAuthenticated } = storeToRefs(useAuthStore());

const router = useRouter();

useOneTap({
  disableAutomaticPrompt: isAuthenticated.value,
  onSuccess: async (response) => {
    const { credential } = response;
    await googleLoginApi(credential);
  },
  onError: () => {
    router.push("/signin");
    // notifyError({
    //   title: "Failed to authenticate!!!",
    //   text:
    //     "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.",
    // });
  },
});
</script>

<template>
  <div></div>
</template>

<style scoped></style>
