<script setup>
import { showPrivacyPolicyDialog, showTermsOfServiceDialog } from "~/utils/dialogActions";
import { CALENDLY_15_MIN_CALL_URL } from "../../utils/constants";
import { BookACall } from "#components";

const BASE_URL = "https://smartlnks.com";

const menus = ref([
  {
    title: "Resources",
    items: [
      { text: "Blog", to: "/blog" },
      { text: "FAQ", to: `${BASE_URL}#faq` },
      { text: "Pricing", to: `${BASE_URL}#pricing` },
      { text: "Features", to: `${BASE_URL}#features` },
    ],
  },
  {
    title: "Company",
    items: [
      { text: "Status", to: "https://status.smartlnks.co", external: true },
      { text: "Our Story", to: `${BASE_URL}#our-story` },
      { text: "Contact & Support", to: `${BASE_URL}#contact` },
      { text: "Terms of Service", to: "/terms" },
      { text: "Privacy Policy", to: "/privacy" },
    ],
  },
  {
    title: "Quick Links",
    items: [
      { text: "Book a Call", to: CALENDLY_15_MIN_CALL_URL, external: true },
      {
        text: "Chrome Extension",
        to:
          "https://chromewebstore.google.com/detail/smartlnks-smartlink-gener/eknnlggodifdikeogfnojbhkkjibfped?utm_source=Smartlnks&utm_medium=referral",
        external: true,
      },
      {
        text: "Firefox Extension",
        to:
          "https://addons.mozilla.org/en-US/firefox/addon/smartlink-generator-app-opener/",
        external: true,
      },
      { text: "Telegram Bot", to: "https://telegram.smartlnks.com", external: true },
      { text: "YouTube Permanent Link", to: "/smartytlink" },
    ],
  },
]);
</script>

<template>
  <div v-for="menu in menus" :key="menu.title" class="w-full px-4 sm:w-1/2 lg:w-2/12">
    <div class="w-full mb-10">
      <h3 class="text-lg font-semibold text-dark dark:text-white mb-9">
        {{ menu.title }}
      </h3>
      <ul class="space-y-3">
        <li v-for="item in menu.items" :key="item.text">
          <template v-if="item.component">
            <component :is="item.component"></component>
          </template>
          <template v-else>
            <NuxtLink
              :to="item.to"
              :target="item?.external ? '_blank' : ''"
              class="inline-block text-base leading-loose text-body-color hover:text-primary dark:text-dark-6"
            >
              {{ item.text }}
            </NuxtLink>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<style></style>
