<script setup></script>

<template>
  <Message class="w-full relative z-50 m-0 md:block hidden" id="alert-message" severity="info">
    <NuxtLink
      to="https://extension.smartlnks.com"
      target="_blank"
      class="text-center md:mx-10 mx-2"
      >Psst... 🌟 Make links awesome! 🌈 Grab our Browser extension now! ✨</NuxtLink
    >
  </Message>
</template>

<style lang="postcss">
#alert-message > .p-message-wrapper > .p-message-text {
  @apply flex w-full justify-center;
}
</style>
